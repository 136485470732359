import { List, Set, fromJS } from 'immutable';
import { cloneDeep } from 'lodash/fp';
import { put, putResolve, select } from 'redux-saga/effects';

import { ENTITY_TYPE_PRODUCTVERSION } from 'constants/entities';
import { getOrganizationsForTargetMarket } from 'core/api/organization';
import { isRetailer } from 'core/api/user';
import { FEATURE_PRODUCTPICTURE_JSON_STORE_INPUT } from 'modules/feature-flag';
import {
  selectHasExclusiveProductActiveRangeSkipValidation,
  selectHasFeature,
  selectHasMultipleLocales,
} from 'modules/feature-flag/selectors';
import { selectEditedRoots } from 'modules/logistical-hierarchies/selectors';
import { GROUP_STATUS_ACTIVE } from 'modules/publication-summary/constants';
import { selectSupportedLocalesForRecipientsIds } from 'modules/recipients/reducers';
import { selectUser } from 'modules/user';
import {
  selectFilteredRecipients,
  selectRuleSets,
} from 'modules/view-as/selectors';
import {
  selectCurrentProductVersionGTIN,
  selectGroupScopes,
  selectIsExclusive,
  selectIsHeterogeneousLogisticalUnit,
  selectProductKeyId,
} from 'reducers/productVersion';
import serviceProductApi from 'resources/serviceProductApi';
import { size, toJsIfImmutable } from 'utils/immutable';
import { logError } from 'utils/logging';
import { selectEnrichedData } from 'utils/selectors/product-data';
import { replaceMigratedMediaFieldsInRule } from 'utils/validation-helpers';

import { NEW_TO_OLD_FIELD_NAME_MAPPING, ruleEntities } from '../constants';

import {
  ADD_STATIC_WARNING,
  DELETE_STATIC_WARNING,
  RECEIVE_RULE_RESULTS,
  RECEIVE_RULE_RESULTS_FOR_SELECTED_RECIPIENTS,
  UPDATE_ASSET_HAS_ERROR_BY_ASSET_ID,
  UPDATE_MEDIA_HAS_ERROR_BY_RULE_ID,
  VALIDATION_RESET,
} from './events';
import { isMigratedMediaFieldRule, isValidEntityKind } from './helpers';

const getRecipientIds = (
  recipients,
  selectedRecipientIds,
  hasLongFlag,
  isExclusive,
  scopes,
) => {
  let filtered = recipients;

  if (hasLongFlag && isExclusive) {
    const activeScopes = (scopes || List())
      .filter((s) => s.status === GROUP_STATUS_ACTIVE)
      .map((s) => s.groupId);

    filtered = filtered.filter(
      (r) =>
        (selectedRecipientIds && selectedRecipientIds.includes(r.get('id'))) ||
        activeScopes.includes(r.getIn(['group', 'id'])),
    );
  }

  return filtered.map((recipient) => recipient.get('id'));
};

export function copyMigratedMediaFieldValuesToOldNames(
  data,
  hasMediaFieldsMigrationFlag,
) {
  if (hasMediaFieldsMigrationFlag) {
    const updatedData = cloneDeep(data);
    updatedData.assets?.pictures?.forEach((picture) => {
      Object.keys(NEW_TO_OLD_FIELD_NAME_MAPPING).forEach((newField) => {
        picture[NEW_TO_OLD_FIELD_NAME_MAPPING[newField]] =
          picture[newField]?.id ?? picture[newField];
      });
    });
    return updatedData;
  }
  return data;
}

export function updateMigratedMedialFieldRules(
  results,
  hasMediaFieldsMigrationFlag,
) {
  if (hasMediaFieldsMigrationFlag) {
    return results.set(
      'rules',
      results.get('rules', []).map((rule) => {
        const _rule = toJsIfImmutable(rule);
        if (isMigratedMediaFieldRule(_rule)) {
          return rule.set(
            'paths',
            fromJS(replaceMigratedMediaFieldsInRule(_rule).paths),
          );
        }
        return rule;
      }),
    );
  }
  return results;
}

export const applyRulesForSpecificRecipients =
  ({
    entityKind = ENTITY_TYPE_PRODUCTVERSION,
    recipientIds = [],
    withHierarchy = false,
    languages = null,
    withByLanguages = false,
    targetMarketId = null,
    filterRecipientsByTargetMarket = false,
    enrichedData = null,
  }: {
    entityKind?: string;
    recipientIds?: number[] | List<number>;
    withHierarchy?: boolean;
    languages?: null | any[] | List<any> | Set<any>;
    withByLanguages?: boolean;
    targetMarketId?: null | number;
    filterRecipientsByTargetMarket?: boolean;
    enrichedData?: null | any;
  } = {}) =>
  async (_dispatch, getState) => {
    if (!isValidEntityKind(entityKind)) {
      return null;
    }
    const state = getState();
    const user = selectUser(state);
    const hasMediaFieldsMigrationFlag = selectHasFeature(
      FEATURE_PRODUCTPICTURE_JSON_STORE_INPUT,
    )(state);
    const productKeyId = selectProductKeyId(state);
    const { data, language: currentDisplayedLanguage } =
      enrichedData || selectEnrichedData(state);
    if (!data || !currentDisplayedLanguage) {
      return null;
    }

    let allRecipientsWorkingWith = selectFilteredRecipients(state);
    if (filterRecipientsByTargetMarket) {
      allRecipientsWorkingWith = getOrganizationsForTargetMarket(
        allRecipientsWorkingWith,
        targetMarketId,
      );
    }

    const isExclusive = selectIsExclusive(state);
    const scopes = selectGroupScopes(state);
    const hasLongFlag =
      selectHasExclusiveProductActiveRangeSkipValidation(state);

    allRecipientsWorkingWith = getRecipientIds(
      allRecipientsWorkingWith,
      recipientIds,
      hasLongFlag,
      isExclusive,
      scopes,
    );

    if (size(allRecipientsWorkingWith) === 0 && !isRetailer(user)) {
      // No recipient to validate for
      return null;
    }

    // The lastRequest is already merged into the data, and the data
    // is enriched with the view as fields: to ensure validation
    // does not mix up the path we remove the requests, which have
    // no added value over the data anyway.
    for (const sharingUnit of data.sharingUnits) {
      delete sharingUnit.lastRequest;
    }

    const body: {
      data: any;
      product_key_id: number;
      languages: any[];
      with_by_language: boolean;
      target_organization_ids?: number[] | null;
      all_recipients_ids: number[];
      rule_set_ids?: number[];
    } = {
      data: copyMigratedMediaFieldValuesToOldNames(
        data,
        hasMediaFieldsMigrationFlag,
      ),
      product_key_id: productKeyId,
      languages: selectHasMultipleLocales(state)
        ? languages ||
          (selectSupportedLocalesForRecipientsIds(state)(recipientIds) as any)
        : [currentDisplayedLanguage],
      with_by_language: selectHasMultipleLocales(state)
        ? withByLanguages
        : false,
      target_organization_ids: Array.isArray(recipientIds)
        ? recipientIds
        : List.isList(recipientIds)
          ? recipientIds.toArray()
          : null,
      all_recipients_ids: allRecipientsWorkingWith,
    };

    let results: any;
    if (withHierarchy) {
      try {
        body.all_recipients_ids = allRecipientsWorkingWith;
        const response =
          await serviceProductApi.checkProductWithHierarchy(body);
        results = fromJS(response.data);
      } catch (e) {
        logError(e);
      }
    } else {
      const ruleSets = selectRuleSets(state).filter((r) => r.get('checked'));
      if (ruleSets.size) {
        body.rule_set_ids = ruleSets.map((r) => r.get('id'));
      }
      try {
        const response: any = await serviceProductApi.checkProduct(body);
        // KLUDGE: KAT-586 Duplicate existing failing
        // logistical unit rules as consumer unit rules
        // to display them on the product page of the HLU.
        if (selectIsHeterogeneousLogisticalUnit(state)) {
          const heterogeneousCaseRules =
            getHeterogeneousCaseRulesFromLogisticalRules(
              response.data.rules,
              selectCurrentProductVersionGTIN(state),
            );
          response.data.rules.push(...heterogeneousCaseRules);
        }

        results = fromJS(response.data).setIn(['entity', 'id'], data.id);
      } catch (e) {
        logError(e);
      }
    }

    return updateMigratedMedialFieldRules(results, hasMediaFieldsMigrationFlag);
  };

export function getHeterogeneousCaseRulesFromLogisticalRules(
  rules,
  currentGTIN,
) {
  return rules
    .filter(
      (rule) =>
        [
          ruleEntities.LOGISTICAL_UNIT,
          ruleEntities.LOGISTICAL_HIERARCHY,
          ruleEntities.LOGISTICAL_HIERARCHY_TOP_UNIT,
        ].includes(rule.entityType) &&
        rule.paths.failed_gtins &&
        rule.paths.failed_gtins.some((gtin_path) => {
          return gtin_path.includes(currentGTIN);
        }),
    )
    .map((rule) => {
      const fieldName = rule.root_fields[0];
      return {
        ...rule,
        status_by_recipients: {
          ...rule.status_by_recipients,
          generic: {
            status: rule.status,
            status_by_language: rule.status_by_language,
          },
        },
        paths: {
          a: [
            fieldName,
            rule.paths.a
              ? rule.paths.a[0].substring(rule.paths.a[0].indexOf(fieldName))
              : '',
          ],
        },
        entityType: ruleEntities.CONSUMER_UNIT,
      };
    });
}

export function* applyRulesSaga(action: { payload?: any } = {}) {
  try {
    const { entityKind, withSpecificRecipients, languages, withByLanguages } =
      action.payload || {};
    const enrichedData = yield select(selectEnrichedData);
    const { data } = enrichedData;

    const results = yield putResolve(
      applyRulesForSpecificRecipients({
        entityKind,
        recipientIds: withSpecificRecipients,
        withHierarchy: false,
        languages,
        withByLanguages,
        enrichedData,
      }) as any,
    );

    yield put({
      type: RECEIVE_RULE_RESULTS,
      data: results,
      versionId: data.id,
      sharingUnits: data.sharingUnits,
      logisticalUnits: yield select(selectEditedRoots),
      hasMediaFieldsMigrationFlag: yield select(
        selectHasFeature(FEATURE_PRODUCTPICTURE_JSON_STORE_INPUT),
      ),
    });

    yield put({
      type: RECEIVE_RULE_RESULTS_FOR_SELECTED_RECIPIENTS,
      data: results,
      versionId: data.id,
    });
    return results;
  } catch (err) {
    logError(err);
  }
}

export const resetValidation = () => ({
  type: VALIDATION_RESET,
});

export const addStaticWarning = () => ({
  type: ADD_STATIC_WARNING,
});

export const deleteStaticWarning = () => ({
  type: DELETE_STATIC_WARNING,
});

export const updateAssetHasErrorByAssetId = (assetId, hasError) => ({
  type: UPDATE_ASSET_HAS_ERROR_BY_ASSET_ID,
  assetId,
  hasError,
});

export const updateMediaHasErrorByRuleId = (assetHasErrorByRuleId) => ({
  type: UPDATE_MEDIA_HAS_ERROR_BY_RULE_ID,
  assetHasErrorByRuleId,
});
